import { Radio, Flex } from 'antd';
import styled from 'styled-components';
import { CommonSoftwareAppVersionsList } from './CommonSoftwareAppVersionsList';
import { ProjectSoftwareAppVersionsList } from './ProjectSoftwareAppVersionsList';
import { CommonSoftwareAppUsageList } from './CommonSoftwareAppUsageList';
import { usePermissions } from '../../session/hooks/usePermissions';
import { AddCommonSoftwareAppVersion } from './AddCommonSoftwareAppVersion';
import { AddProjectSoftwareAppVersion } from './AddProjectSoftwareAppVersion';
import { useSearchParameter } from '../../../contexts/navigation/hooks/useSearchParameter';
import { ShiftedDrawer } from '../../shared/components/ShiftedDrawer';

import type { Project } from '../../../api/engineering/domain/types';
import type { ScopedSoftwareApp } from '../hooks/scopedSoftwareApp';


const RadioGroup = styled(Radio.Group)`
  label {
    height: 40px;
    font-weight: 500 !important;
    text-align: center;
    margin-top: 0 !important;
  }

  label > span {
    display: flex;
    align-items: center;
    height: 100%;
  }
`;

type Props = {
    app: ScopedSoftwareApp;
    onClose: () => void;
    open: boolean;
    project?: Project;
};

export const SoftwareAppVersionsDrawer = (props: Props) => {
    const { app, project } = props;

    const isProjectScope = app.scope === 'project';
    const permissions = usePermissions({ projectId: project?.idProject.toString() ?? '', softwareAppId: app.idSoftwareApp.toString() });
    const [usageViewSearchParam, setUsageViewSearchParam] = useSearchParameter('view');
    const tab = usageViewSearchParam || 'versions';

    const options = [
        { label: 'Version details', value: 'versions' },
        { label: 'Project integration', value: 'usage' }
    ];

    return (
        <ShiftedDrawer
            open={props.open}
            onClose={props.onClose}
            title={`Versions overview:  ${app.name || ''}`}
            extra={(
                <>
                    {permissions.engineeringSvc$addCommonSoftwareAppVersion && !isProjectScope && (
                        <AddCommonSoftwareAppVersion 
                            key="addVersion" 
                            existingSoftwareAppVersion={app.latestVersion} 
                            parentApp={app} 
                        />
                    )}
                    {permissions.engineeringSvc$addProjectSoftwareAppVersion && isProjectScope && !!project && (
                        <AddProjectSoftwareAppVersion 
                            key="addProjectVersion" 
                            existingSoftwareAppVersion={app.latestVersion} 
                            parentApp={app} 
                            projectId={project.idProject.toString()} 
                        />
                    )}
                </>
            )}
        >
            <Flex vertical gap='1em'>
                {!isProjectScope && (
                    <RadioGroup options={options} onChange={(e) => setUsageViewSearchParam(e.target.value)} value={tab} optionType="button" buttonStyle="solid" />
                )}

                {isProjectScope && !!project
                    ? <ProjectSoftwareAppVersionsList softwareApp={app} project={project} />
                    : (
                    <>
                        {tab === 'versions' && (
                            <CommonSoftwareAppVersionsList 
                                softwareApp={app} 
                                project={project} 
                            />
                        )}

                        {tab === 'usage' && <CommonSoftwareAppUsageList softwareApp={app} />}
                    </>
                )}
            </Flex>
        </ShiftedDrawer>
    );
};
