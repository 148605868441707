import { Badge, Space, Flex, Alert, Row, Divider } from 'antd';
import styled from 'styled-components';

import { useProjectAccessRequests } from '../hooks/useProjectAccessRequests';
import { ProjectAccessRequestListItem } from './ProjectAccessRequestListItem';
import { ProjectSectionTitle } from './ProjectSectionTitle';

import type { Project, ProjectMemberAccessRequest } from '../../../api';

const StyledAlert = styled(Alert)`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colorWarning};
  background-color: ${({ theme }) => `rgb(from ${theme.colorWarning} r g b / .05)`};
`;

export const ProjectAccessRequests = (props: { project: Project }) => {
  const accessRequests = useProjectAccessRequests(props.project.idProject);

  const hasAccessRequests = accessRequests.data && accessRequests.data.length > 0;

  const RequestList = (requests: ProjectMemberAccessRequest[]) => (
    <Space direction="vertical">
      {requests.map((r, i) => (
        <ProjectAccessRequestListItem key={`${i}-${r.idMember}`} project={props.project} accessRequest={r} />
      ))}
    </Space>
  );

  return (
    <>
      {hasAccessRequests && (
        <>
          <Row>
            <StyledAlert
              message={
                <Flex vertical gap="large">
                  <Space>
                    <ProjectSectionTitle style={{ margin: 0 }} level={5} regular>
                      Project Access Requests
                    </ProjectSectionTitle>

                    <Badge count={accessRequests.data.length} />
                  </Space>

                  {RequestList(accessRequests.data)}
                </Flex>
              }
            />
          </Row>

          <Divider />
        </>
      )}
    </>
  );
};
