import { Row, Col, Card, Layout, Typography } from 'antd';
import { useState } from 'react';
import { SoftwareAppSelection } from '../../../domain';
import { usePermissions } from '../../session/hooks/usePermissions';
import { AddCommonSoftwareApp } from './AddCommonSoftwareApp';
import { CommonSoftwareAppsList } from './CommonSoftwareAppsList';
import { PageHeader } from '@ant-design/pro-layout';
import BreadcrumbsContainer from '../../../contexts/projects/components/BreadcrumbsContainer';
import styled from 'styled-components';

const StyledTitle = styled(Typography.Title)`
  margin: 0 !important;
  margin-bottom: 4px !important;
`;

const StyledPageHeader = styled(PageHeader)`
  position: sticky !important;
  top: 64px !important;
  z-index: 4 !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  background-color: ${({ theme }) => theme.colorBgLayout} !important;
`;

const StyledCard = styled(Card)`
  margin: 0 10px !important;
`;

export const CommonAppsView = () => {
  const [selected, setSelected] = useState([] as SoftwareAppSelection[]);
  const [initialSelection] = useState([] as SoftwareAppSelection[]);
  const permissions = usePermissions();

  return (
    <Layout>
      <BreadcrumbsContainer items={[{ title: 'Software platform' }]} />
      <StyledPageHeader
        title={<StyledTitle level={4}>Software Platform Overview</StyledTitle>}
        extra={[permissions.engineeringSvc$addCommonSoftwareApp && <AddCommonSoftwareApp key="add" />]}
      />
      <StyledCard>
        <Row>
          <Col span={24}>
            <CommonSoftwareAppsList selected={selected} onSelect={setSelected} initiallySelected={initialSelection} />
          </Col>
        </Row>
      </StyledCard>
    </Layout>
  );
};
